<template>
    <Fragment>
        <DataTableComponent
            :headers="headers"
            :items="convocatorias.data"
            :show_loading="convocatorias.isLoading"
            :total_registros="total"
            v-models:pagina="paginaActual"
            v-models:select="registrosPorPagina"
            @paginar="manejarPaginacionRegistros"
        >
            <template v-slot:[`item.fecha_recepcion`]="{ item }">
                {{ formatDatetime(item.fecha_recepcion) }}
            </template>
            <template v-slot:[`item.fecha_evaluacion`]="{ item }">
                {{ formatDate(item.fecha_evaluacion) }}
            </template>
            <template v-slot:[`item.fecha_resultados`]="{ item }">
                {{ formatDate(item.fecha_resultados) }}
            </template>
            <template v-slot:[`item.estado`]="{ item }">
                <v-chip label>
                    <v-icon class="mr-1" style="font-size: 22px !important;">{{ obtenerIconoEstadoConvocatoria(item) }}</v-icon>
                    {{ item.estado.nombre }}
                </v-chip>
            </template>
            <template v-slot:[`item.tipo_convocatoria`]="{ item }">
                <v-chip v-if="item.proceso_publico" label class="px-2">
                    <v-icon class="mr-1" style="font-size: 22px !important;">mdi-earth</v-icon>
                    Pública
                </v-chip>
                <v-chip v-else label class="px-2">
                    <v-icon class="mr-1" style="font-size: 22px !important;">mdi-lock</v-icon>
                    Reservada
                </v-chip>
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
                <div class="d-flex justify-center align-center" style="gap: 8px;">
                    <v-tooltip :key="10" v-if="mostrarParticipar(item) && item.estado.codigo === 'RECEPCION'" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-bind="attrs"
                                v-on="on"
                                style="order: 10" 
                                color="primary" 
                                icon
                                @click.stop="abrirConfirmacionParticipacion(item)" 
                            >
                                <v-icon>mdi-account-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Participar</span>
                    </v-tooltip>

                    <v-tooltip :key="20" v-if="item.adjunto" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-bind="attrs" 
                                v-on="on"
                                style="order: 20" 
                                color="primary" 
                                icon
                                @click.stop="visualizarAdjuntoConvocatoria(item)" 
                            >
                                <v-icon>mdi-file-pdf-box</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver documento convocatoria</span>
                    </v-tooltip>

                    <template v-if="mostrarBotonAdjuntos(item)">
                        <v-tooltip :key="30" v-if="item.estado.codigo === 'RECEPCION'" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn 
                                    v-bind="attrs" 
                                    v-on="on"
                                    style="order: 30" 
                                    color="primary" 
                                    icon
                                    @click.stop="abrirModalEdicionAdjunto(item)" 
                                >
                                    <v-icon>mdi-offer</v-icon>
                                </v-btn>
                            </template>
                            <span>Subir oferta</span>
                        </v-tooltip>
                        <v-tooltip :key="40" v-if="item.participacion?.adjunto" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs" 
                                    v-on="on"
                                    style="order: 40"
                                    color="primary"
                                    icon
                                    @click.stop="visualizarAdjuntoParticipacion(item)"
                                >
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span>Ver oferta</span>
                        </v-tooltip>
                    </template>

                    <v-tooltip :key="50" v-if="item.participacion && item.participacion.id_estado_participacion_prov !== 2" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-bind="attrs" 
                                v-on="on" 
                                style="order: 50" 
                                color="primary" 
                                icon
                                @click.stop="$router.push(`/cm-documentos-convocatoria/${item.id}`)" 
                            >
                                <v-icon>mdi-archive</v-icon>
                            </v-btn>
                        </template>
                        <span class="d-inline-block text-center">Ver documentos de la convocatoria</span>
                    </v-tooltip>
                </div>
            </template>
        </DataTableComponent>
        <!-- Confirmacion participacion -->
        <ConfirmationModalComponent 
            :is-open="confirmacionParticipacionAbierta"
            description="¿Desea participar en esta convocatoria?"
            :isLoading="participacion.isLoading"
            @confirm="participarEnConvocatoria"
            @cancel="confirmacionParticipacionAbierta = false"
        />
        <!-- modificacion adjuntos -->
        <AdjuntoConvocatoria 
            :is-open="modalEdicionAdjuntoAbierta"
            @on-save="confirmarEdicionAdjunto"
            @on-visibility-change="manejarVisibilidadEditarOferta"
        />
        <ConfirmationModalComponent 
            :is-open="confirmacionEdicionAdjuntoAbierta"
            description="¿Desea actualizar el adjunto?"
            :is-loading="edicionAdjunto.isLoading"
            @confirm="editarAdjunto"
            @cancel="confirmacionEdicionAdjuntoAbierta = false"
        />
        <!-- Visualizacion archivos -->
        <PdfModal
            :isOpen="modalVisualizacionAdjuntoAbierta" 
            :source-loadable="visualizacionAdjunto" 
            @on-visibility-change="manejarVisibilidadModalVisualizacionAdjunto"
            filename="adjunto-participacion"
        />
        <!-- Visualizacion archivo convocatoria -->
        <PdfModal
            :isOpen="modalVisualizacionAdjuntoConvocatoriaAbierta" 
            :source-loadable="visualizacionAdjuntoConvocatoria" 
            @on-visibility-change="manejarVisibilidadModalVisualizacionAdjuntoConvocatoria"
            filename="adjunto-convocatoria"
        />
    </Fragment>
</template>
<script>
import DataTableComponent from '@/components/DataTableComponent.vue';
import { ConfirmationModalComponent } from '@/components/utils';
import { 
    createPageable, 
    togglePageable, 
    setPageableResponse, 
    createLoadable, 
    setLoadableResponse, 
    isResponseSuccesful, 
toggleLoadable
} from '@/utils/loadable';
import AdjuntoConvocatoria from './AdjuntoConvocatoria.vue'
import PdfModal from '@/components/PdfModal.vue';
import moment from 'moment';

export default {
    name: 'ListaConvocatoriasProveedor',
    components: { DataTableComponent, ConfirmationModalComponent, AdjuntoConvocatoria, PdfModal },
    data: () => ({
        headers: [
            { align: 'center', sortable: false, text: 'Encabezado', value: 'encabezado' },
            { align: 'center', sortable: false, text: 'Fecha y hora de recepción', value: 'fecha_recepcion' },
            { align: 'center', sortable: false, text: 'Fecha de evaluación', value: 'fecha_evaluacion' },
            { align: 'center', sortable: false, text: 'Fecha de resultados', value: 'fecha_resultados' },
            { align: 'center', sortable: false, text: 'Tipo', value: 'tipo_convocatoria' },
            { align: 'center', sortable: false, text: 'Estado', value: 'estado' },
            { align: 'center', sortable: false, text: 'Acciones', value: 'acciones' },
        ],
        convocatorias: createPageable([], 10),
        paginaActual: 1,
        registrosPorPagina: 10,
        convocatoriaActiva: null,
        // Participacion
        confirmacionParticipacionAbierta: false,
        participacion: createLoadable(null),
        // Edicion adjunto
        modalEdicionAdjuntoAbierta: false,
        confirmacionEdicionAdjuntoAbierta: false,
        edicionAdjuntoInfo: null,
        edicionAdjunto: createLoadable(null),
        // Visualizacion
        modalVisualizacionAdjuntoConvocatoriaAbierta: false,
        modalVisualizacionAdjuntoAbierta: false,
        visualizacionAdjunto: createLoadable(null),
        visualizacionAdjuntoConvocatoria: createLoadable(null),
    }),
    computed: {
        total() {
            return this.convocatorias.pagination.total_rows;
        },
    },
    methods: {
        formatDate(fecha) {
            return fecha ? moment(fecha).format("DD-MM-YYYY") : '';
        },
        formatDatetime(fecha) {
            return fecha ? moment(fecha).format("DD-MM-YYYY HH:mm:ss") : '';
        },
        obtenerIconoEstadoConvocatoria(convocatoria) {
            const estado = convocatoria.estado.codigo;
            switch (estado) {
                case 'RECEPCION':
                    return 'mdi-email-arrow-left';
                case 'EVALUACION':
                    return 'mdi-progress-pencil';
                case 'RESULTADOS':
                    return 'mdi-order-bool-descending-variant';
                default: 
                    return 'mdi-timer-off';
            };
        },
        manejarPaginacionRegistros(paginacion) {
            const { pagina, cantidad_por_pagina } = paginacion;
            this.paginaActual = pagina;
            this.registrosPorPagina = cantidad_por_pagina;
            this.cargarConvocatorias();
        },
        async cargarConvocatorias() {
            togglePageable(this.convocatorias);
            const filtros = {
                pagination: true,
                per_page: this.registrosPorPagina,
                page: this.paginaActual,
            };

            const { data, headers } = await this.services.ConvocatoriaServices.cargarListaConvocatoriasProveedor(filtros);
            setPageableResponse(this.convocatorias, data, headers);
        },
        mostrarParticipar(convocatoria) {
            const { participacion } = convocatoria;
            return !participacion || participacion.id_estado_participacion_prov === 2;
        },
        mostrarBotonAdjuntos(convocatoria) {
            const { participacion } = convocatoria;
            return participacion && participacion.id_estado_participacion_prov === 1;
        },
        // Participacion
        abrirConfirmacionParticipacion(convocatoria) {
            this.convocatoriaActiva = convocatoria;
            this.confirmacionParticipacionAbierta = true;
        },
        async participarEnConvocatoria() {
            const idConvocatoria = this.convocatoriaActiva.id;
            togglePageable(this.participacion);
            const { data } = await this.services.ConvocatoriaServices.participarEnConvocatoria(idConvocatoria);
            setLoadableResponse(this.participacion, data, { skipOnSuccess: true, showAlertOnSuccess: true });
            this.confirmacionParticipacionAbierta = false;
            
            if (!isResponseSuccesful(data)) {
                if (data.error.code === 'TIME_OUT_ETAPA_RECEPCION') this.cargarConvocatorias();
                return;
            }
            
            this.cargarConvocatorias();
        },  
        // Edicion adjunto
        abrirModalEdicionAdjunto(convocatoria) {
            this.convocatoriaActiva = convocatoria;
            this.modalEdicionAdjuntoAbierta = true;
        },
        confirmarEdicionAdjunto(adjunto) {
            this.edicionAdjuntoInfo = adjunto;
            this.modalEdicionAdjuntoAbierta = false;
            this.confirmacionEdicionAdjuntoAbierta = true;
        },
        async editarAdjunto() {
            const idParticipacion = this.convocatoriaActiva.participacion.id;
            const formData = new FormData();
            formData.append('adjunto', this.edicionAdjuntoInfo);

            toggleLoadable(this.edicionAdjunto)
            const { data } = await this.services.ConvocatoriaServices.editarAdjuntoParticipacion(idParticipacion, formData);
            setLoadableResponse(this.edicionAdjunto, data, { skipOnSuccess: true, showAlertOnSuccess: true });
            this.confirmacionEdicionAdjuntoAbierta = false;

            if (!isResponseSuccesful(data)) return;
            this.cargarConvocatorias();
        },
        manejarVisibilidadEditarOferta(visible) {
            this.modalEdicionAdjuntoAbierta = visible;
        },
        // Visualizacion adjunto
        manejarVisibilidadModalVisualizacionAdjunto(visible) {
            this.modalVisualizacionAdjuntoAbierta = visible;
        },
        manejarVisibilidadModalVisualizacionAdjuntoConvocatoria(visible) {
            this.modalVisualizacionAdjuntoConvocatoriaAbierta = visible;
        },
        async visualizarAdjuntoParticipacion(convocatoria) {
            this.modalVisualizacionAdjuntoAbierta = true;
            const idParticipacion = convocatoria.participacion.id;
            toggleLoadable(this.visualizacionAdjunto);
            const { data } = await this.services.ConvocatoriaServices.visualizarAdjuntoParticipacion(idParticipacion);
            setLoadableResponse(this.visualizacionAdjunto, data, { isFile: true });
        },
        // Visualizacion convocatoria
        async visualizarAdjuntoConvocatoria(convocatoria) {
            this.modalVisualizacionAdjuntoConvocatoriaAbierta = true;
            const idConvocatoria = convocatoria.id;
            toggleLoadable(this.visualizacionAdjuntoConvocatoria);
            const { data } = await this.services.ConvocatoriaServices.visualizarAdjuntoConvocatoria(idConvocatoria);
            setLoadableResponse(this.visualizacionAdjuntoConvocatoria, data, { isFile: true });
        },
    },
    created() {
        this.cargarConvocatorias();
    },
}
</script>