<template>
    <Fragment>
        <v-btn
            class="mb-4" 
            color="primary" 
            @click.stop="abrirModalCreacionConvocatoria"
        >
            Crear convocatoria
        </v-btn>
        <DataTableComponent
            :headers="headers"
            :items="convocatorias.data"
            :show_loading="convocatorias.isLoading"
            :total_registros="total"
            v-models:pagina="paginaActual"
            v-models:select="registrosPorPagina"
            @paginar="manejarPaginacionRegistros"
        >
            <template v-slot:[`item.fecha_recepcion`]="{ item }">
                {{ formatDatetime(item.fecha_recepcion) }}
            </template>
            <template v-slot:[`item.fecha_evaluacion`]="{ item }">
                {{ formatDate(item.fecha_evaluacion) }}
            </template>
            <template v-slot:[`item.fecha_resultados`]="{ item }">
                {{ formatDate(item.fecha_resultados) }}
            </template>
            <template v-slot:[`item.estado`]="{ item }">
                <v-chip label>
                    <v-icon class="mr-1" style="font-size: 22px !important;">{{ obtenerIconoEstadoConvocatoria(item) }}</v-icon>
                    {{ item.estado.nombre }}
                </v-chip>
            </template>
            <template v-slot:[`item.tipo_convocatoria`]="{ item }">
                <v-chip v-if="item.proceso_publico" label class="px-2">
                    <v-icon class="mr-1" style="font-size: 22px !important;">mdi-earth</v-icon>
                    Pública
                </v-chip>
                <v-chip v-else label class="px-2">
                    <v-icon class="mr-1" style="font-size: 22px !important;">mdi-lock</v-icon>
                    Reservada
                </v-chip>
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
                <div class="d-flex justify-center align-center" style="gap: 8px;">
                    <v-tooltip :key="10" v-if="item.estado.codigo === 'RECEPCION'" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-bind="attrs" 
                                v-on="on" 
                                style="order: 10"
                                color="primary" 
                                icon
                                @click.stop="abrirModalInvitacionProveedor(item)"
                            >
                                <v-icon>mdi-account-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Invitar proveedor</span>
                    </v-tooltip>
                    <template v-if="item.estado.codigo !== 'CERRADA'">
                        <v-tooltip :key="20" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn 
                                    v-bind="attrs" 
                                    v-on="on"
                                    style="order: 20" 
                                    color="primary"
                                    icon
                                    @click.stop="abrirModalEdicionConvocatoria(item)"
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Modificar convocatoria</span>
                        </v-tooltip>
                        <v-tooltip :key="30" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn 
                                    v-bind="attrs" 
                                    v-on="on" 
                                    style="order: 30" 
                                    color="primary" 
                                    icon
                                    @click.stop="confirmarCambioVisibilidadConvocatoria(item)"
                                >
                                    <v-icon>{{ item.activo ? 'mdi-lock-open' : 'mdi-lock' }}</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.activo ? 'inhabilitar' : 'habilitar' }} convocatoria</span>
                        </v-tooltip>
                    </template>
                    <v-tooltip :key="40" v-if="item.estado.codigo === 'RECEPCION'" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-bind="attrs"
                                v-on="on"
                                style="order: 40"
                                color="primary"
                                icon
                                @click.stop="abrirModalEdicionAdjunto(item)"
                            >
                                <v-icon>mdi-file-edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Modificar adjunto</span>
                    </v-tooltip>
                    <v-tooltip :key="50" v-if="item.adjunto" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-bind="attrs" 
                                v-on="on" 
                                style="order: 50" 
                                color="primary" 
                                icon
                                @click.stop="visualizarAdjunto(item)"
                            >
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver adjunto</span>
                    </v-tooltip>
                    <v-tooltip :key="60" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-bind="attrs" 
                                v-on="on" 
                                color="primary" 
                                style="order: 60"
                                icon
                                @click.stop="$router.push(`/cm-convocatorias/aprobacion/${item.id}`)"
                            >
                                <v-icon>mdi-list-box</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver ofertas</span>
                    </v-tooltip>
                    <v-tooltip :key="70" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-bind="attrs" 
                                v-on="on"
                                style="order: 60"
                                color="primary" 
                                icon
                                @click.stop="$router.push(`/cm-documentos-convocatoria/${item.id}`)" 
                            >
                                <v-icon>mdi-archive</v-icon>
                            </v-btn>
                        </template>
                        <span class="d-inline-block text-center">Ver documentos de la convocatoria</span>
                    </v-tooltip>
                </div>
            </template>
        </DataTableComponent>
        <!-- creacion de convocatoria -->
        <CrearConvocatoria 
            :is-open="modalCreacionConvocatoriaAbierta"
            @on-visibility-change="manejarVisibilidadModalCreacionConvocatoria"
            @on-save="confirmarCreacionConvocatoria"
        />
        <ConfirmationModalComponent 
            :is-open="confirmacionCreacionAbierta"
            description="¿Desea continuar con la creación de la convocatoria?"
            :is-loading="creacionConvocatoria.isLoading"
            @confirm="crearConvocatoria"
            @cancel="confirmacionCreacionAbierta = false"
        />
        <!-- invitacion convocatoria -->
        <InvitacionConvocatoria 
            :is-open="modalInvitacionProveedorAbierta"
            @on-visibility-change="manejarVisibilidadModalInvitacionProveedor"
            @on-invite="confirmarInvitacionProveedor"
        />
        <ConfirmationModalComponent 
            :is-open="confirmacionInvitacionProveedorAbierta"
            :description="mensajeInvitacionProveedor"
            :is-loading="invitacionProveedor.isLoading"
            @confirm="invitarProveedor"
            @cancel="confirmacionInvitacionProveedorAbierta = false"
        />
        <!-- creacion de convocatoria -->
        <EditarConvocatoria 
            :is-open="modalEdicionConvocatoriaAbierta"
            :convocatoria="convocatoriaActiva"
            @on-visibility-change="manejarVisibilidadModalEdicionConvocatoria"
            @on-save="confirmarEdicionConvocatoria"
        />
        <ConfirmationModalComponent 
            :is-open="confirmacionEdicionAbierta"
            description="¿Desea actualizar la convocatoria?"
            :is-loading="edicionConvocatoria.isLoading"
            @confirm="editarConvocatoria"
            @cancel="confirmacionEdicionAbierta = false"
        />
        <!-- modificacion adjuntos -->
        <AdjuntoConvocatoria 
            :is-open="modalEdicionAdjuntoAbierta"
            @on-visibility-change="manejarVisibilidadModalEditAdjunto"
            @on-save="confirmarEdicionAdjunto"
        />
        <ConfirmationModalComponent 
            :is-open="confirmacionEdicionAdjuntoAbierta"
            description="¿Desea actualizar el adjunto?"
            :is-loading="edicionAdjunto.isLoading"
            @confirm="editarAdjunto"
            @cancel="confirmacionEdicionAdjuntoAbierta = false"
        />
        <!-- Visualizacion archivos -->
        <PdfModal
            :isOpen="modalVisualizacionAdjuntoAbierta" 
            :source-loadable="visualizacionAdjunto" 
            @on-visibility-change="manejarVisibilidadModalVisualizacionAdjunto"
            filename="adjunto-convocatoria"
        />
        <!-- Cambio de visibilidad -->
        <ConfirmationModalComponent 
            :is-open="confirmacionCambioVisibilidadAbierta"
            :description="`¿Desea ${convocatoriaActiva?.activo ? 'Inhabilitar' : 'Habilitar'} la convocatoria`"
            :is-loading="visibilidadConvocatoria.isLoading"
            @confirm="cambiarVisibilidadConvocatoria"
            @cancel="confirmacionCambioVisibilidadAbierta = false"
        />
    </Fragment>
</template>
<script>
import { 
    createLoadable, 
    createPageable, 
    isResponseSuccesful, 
    setLoadableResponse, 
    setPageableResponse, 
    toggleLoadable, 
    togglePageable 
} from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import CrearConvocatoria from './CrearConvocatoria.vue'
import EditarConvocatoria from './EditarConvocatoria.vue';
import { Fragment } from 'vue-fragment';
import { ConfirmationModalComponent } from '@/components/utils';
import InvitacionConvocatoria from './InvitacionConvocatoria.vue';
import AdjuntoConvocatoria from './AdjuntoConvocatoria.vue'
import { convertToFormData } from '@/utils/data';
import PdfModal from '@/components/PdfModal.vue';
import moment from 'moment';

export default {
    name: 'ListaConvocatoriasAdmin',
    components: {
    CrearConvocatoria,
    EditarConvocatoria,
    DataTableComponent,
    ConfirmationModalComponent,
    Fragment,
    InvitacionConvocatoria,
    AdjuntoConvocatoria,
    PdfModal
},
    data: () => ({
        modalCreacionConvocatoriaAbierta: false,
        modalEdicionConvocatoriaAbierta: false,
        modalInvitacionProveedorAbierta: false,
        modalEdicionAdjuntoAbierta: false,
        modalVisualizacionAdjuntoAbierta: false,
        headers: [
            { align: 'center', sortable: false, text: 'Encabezado', value: 'encabezado' },
            { align: 'center', sortable: false, text: 'Fecha y hora de recepción', value: 'fecha_recepcion' },
            { align: 'center', sortable: false, text: 'Fecha de evaluación', value: 'fecha_evaluacion' },
            { align: 'center', sortable: false, text: 'Fecha de resultados', value: 'fecha_resultados' },
            { align: 'center', sortable: false, text: 'Tipo', value: 'tipo_convocatoria' },
            { align: 'center', sortable: false, text: 'Estado', value: 'estado' },
            { align: 'center', sortable: false, text: 'Acciones', value: 'acciones' },
        ],
        // data
        convocatorias: createPageable([], 10),
        convocatoriaActiva: null,
        paginaActual: 1,
        registrosPorPagina: 10,
        // Creacion de convocatoria
        creacionConvocatoria: createLoadable(null),
        confirmacionCreacionAbierta: false,
        creacionConvocatoriaInfo: null,
        // Edicion de convocatoria
        edicionConvocatoria: createLoadable(null),
        confirmacionEdicionAbierta: false,
        edicionConvocatoriaInfo: null,
        // Invitacion proveedor
        invitacionProveedor: createLoadable(null),
        proveedor: null,
        confirmacionInvitacionProveedorAbierta: false,
        // Edicion adjunto
        confirmacionEdicionAdjuntoAbierta: false,
        edicionAdjuntoInfo: null,
        edicionAdjunto: createLoadable(null),
        // Previsualizacion de archivos
        visualizacionAdjunto: createLoadable(null),
        // Visibilidad convocatorias
        confirmacionCambioVisibilidadAbierta: false,
        visibilidadConvocatoria: createLoadable(null),
    }),
    computed: {
        total() {
            return this.convocatorias.pagination.total_rows;
        },
        mensajeInvitacionProveedor() {
            if (!this.proveedor) return '';

            return `¿Desea invitar al proveedor ${this.proveedor.nombre_comercial} a la convocatoria?`
        },
    },
    methods: {
        formatDate(fecha) {
            return fecha ? moment(fecha).format("DD-MM-YYYY") : '';
        },
        formatDatetime(fecha) {
            return fecha ? moment(fecha).format("DD-MM-YYYY HH:mm:ss") : '';
        },
        obtenerIconoEstadoConvocatoria(convocatoria) {
            const estado = convocatoria.estado.codigo;
            switch (estado) {
                case 'RECEPCION':
                    return 'mdi-email-arrow-left';
                case 'EVALUACION':
                    return 'mdi-progress-pencil';
                case 'RESULTADOS':
                    return 'mdi-order-bool-descending-variant';
                default: 
                    return 'mdi-timer-off';
            };
        },
        abrirModalCreacionConvocatoria() {
            this.modalCreacionConvocatoriaAbierta = true;
        },
        manejarVisibilidadModalCreacionConvocatoria(visible) {
            this.modalCreacionConvocatoriaAbierta = visible;
        },
        manejarVisibilidadModalEdicionConvocatoria(visible) {
            this.modalEdicionConvocatoriaAbierta = visible;
        },
        manejarVisibilidadModalInvitacionProveedor(visible) {
            this.modalInvitacionProveedorAbierta = visible;
        },
        async cargarConvocatorias() {
            togglePageable(this.convocatorias);
            const filtros = {
                pagination: true,
                per_page: this.registrosPorPagina,
                page: this.paginaActual,
            };
            
            const { data, headers } = await this.services.ConvocatoriaServices.cargarListaConvocatoriasAdministrador(filtros);
            setPageableResponse(this.convocatorias, data, headers);
        },
        confirmarCreacionConvocatoria(convocatoria) {
            this.creacionConvocatoriaInfo = convocatoria;
            this.modalCreacionConvocatoriaAbierta = false;
            this.confirmacionCreacionAbierta = true;
        },
        async crearConvocatoria() {
            const formData = convertToFormData(this.creacionConvocatoriaInfo);

            toggleLoadable(this.creacionConvocatoria);
            const { data } = await this.services.ConvocatoriaServices.guardarConvocatoria(formData);

            setLoadableResponse(this.creacionConvocatoria, data, { skipOnSuccess: true, showAlertOnSuccess: true });
            this.confirmacionCreacionAbierta = false;

            if (!isResponseSuccesful(data)) return;
            this.cargarConvocatorias();
        },
        manejarPaginacionRegistros(paginacion) {
            const { pagina, cantidad_por_pagina } = paginacion;
            this.paginaActual = pagina;
            this.registrosPorPagina = cantidad_por_pagina;
            this.cargarConvocatorias();
        },
        // Invitacion proveedor
        abrirModalInvitacionProveedor(convocatoria) {
            this.convocatoriaActiva = convocatoria;
            this.modalInvitacionProveedorAbierta = true;
        },
        confirmarInvitacionProveedor(proveedor) {
            this.proveedor = proveedor;
            this.modalInvitacionProveedorAbierta = false;
            this.confirmacionInvitacionProveedorAbierta = true;
        },
        async invitarProveedor() {
            const idConvocatoria = this.convocatoriaActiva.id;
            const payload = { id_proveedor: this.proveedor.id };
            toggleLoadable(this.invitacionProveedor)
            
            const { data } = await this.services.ConvocatoriaServices.invitarProveedor(idConvocatoria, payload);
            setLoadableResponse(this.invitacionProveedor, data, { skipOnSuccess: true, showAlertOnSuccess: true });
            this.confirmacionInvitacionProveedorAbierta = false;

            if (!isResponseSuccesful(data) && data.error.code === 'TIME_OUT_ETAPA_RECEPCION') {
                this.cargarConvocatorias();
            } 
            
        },
        // Edicion convocatoria
        abrirModalEdicionConvocatoria(convocatoria) {
            this.convocatoriaActiva = { ...convocatoria };
            this.modalEdicionConvocatoriaAbierta = true;
        },
        confirmarEdicionConvocatoria(convocatoria) {
            this.edicionConvocatoriaInfo = convocatoria;
            this.modalEdicionConvocatoriaAbierta = false;
            this.confirmacionEdicionAbierta = true;
        },
        async editarConvocatoria() {
            const idConvocatoria = this.convocatoriaActiva.id;
            const formData = convertToFormData(this.edicionConvocatoriaInfo);

            toggleLoadable(this.edicionConvocatoria);
            const { data } = await this.services.ConvocatoriaServices.editarConvocatoria(idConvocatoria, formData);

            setLoadableResponse(this.edicionConvocatoria, data, { skipOnSuccess: true, showAlertOnSuccess: true });
            this.confirmacionEdicionAbierta = false;

            if (!isResponseSuccesful(data)) return;
            this.cargarConvocatorias();
        },
        // Edicion adjunto
        abrirModalEdicionAdjunto(convocatoria) {
            this.convocatoriaActiva = convocatoria;
            this.modalEdicionAdjuntoAbierta = true;
        },
        confirmarEdicionAdjunto(adjunto) {
            this.edicionAdjuntoInfo = adjunto;
            this.modalEdicionAdjuntoAbierta = false;
            this.confirmacionEdicionAdjuntoAbierta = true;
        },
        async editarAdjunto() {
            const idConvocatoria = this.convocatoriaActiva.id;
            const formData = new FormData();
            formData.append('adjunto', this.edicionAdjuntoInfo);

            toggleLoadable(this.edicionAdjunto)
            const { data } = await this.services.ConvocatoriaServices.editarAdjuntoConvocatoria(idConvocatoria, formData);
            setLoadableResponse(this.edicionAdjunto, data, { skipOnSuccess: true, showAlertOnSuccess: true });
            this.confirmacionEdicionAdjuntoAbierta = false;

            if (!isResponseSuccesful(data)) return;
            this.cargarConvocatorias();
        },
        // Visualizar adjunto
        manejarVisibilidadModalVisualizacionAdjunto(visible) {
            this.modalVisualizacionAdjuntoAbierta = visible;
        },
        manejarVisibilidadModalEditAdjunto(visible) {
            this.modalEdicionAdjuntoAbierta = visible;
        },
        async visualizarAdjunto(convocatoria) {
            this.modalVisualizacionAdjuntoAbierta = true;
            const idConvocatoria = convocatoria.id;
            toggleLoadable(this.visualizacionAdjunto);
            const { data } = await this.services.ConvocatoriaServices.visualizarAdjuntoConvocatoria(idConvocatoria);
            setLoadableResponse(this.visualizacionAdjunto, data, { isFile: true });
        },
        // Cambiar visibilidad convocatoria
        confirmarCambioVisibilidadConvocatoria(convocatoria) {
            this.confirmacionCambioVisibilidadAbierta = true;
            this.convocatoriaActiva = convocatoria;
        },
        async cambiarVisibilidadConvocatoria() {
            const idConvocatoria = this.convocatoriaActiva.id;
            const payload = { activo: !this.convocatoriaActiva.activo }
            toggleLoadable(this.visibilidadConvocatoria);
            const { data } = await this.services.ConvocatoriaServices.cambiarVisibilidadConvocatoria(idConvocatoria, payload);
            setLoadableResponse(this.visibilidadConvocatoria, data, { skipOnSuccess: true, showAlertOnSuccess: true });
            this.confirmacionCambioVisibilidadAbierta = false;

            if (!isResponseSuccesful(data)) return;
            this.cargarConvocatorias();
        },
    },
    created() {
        this.cargarConvocatorias();
    },
}
</script>