<template>
    <v-dialog v-model="internalOpen" max-width="700">
        <div style="background-color: #FFFFFF; padding: 0 1em 0; position: relative;">
            <div class="pt-4 pb-0" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="px-4 py-4"
                    @click.stop="cerrarModal" 
                    text
                    rounded
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <div class="px-1 mt-1">
                <h5 class="mb-0 text-h5" style="color: var(--v-accent-darken4)">Invitar participante</h5>
                <p class="mb-4">Digite el nombre comercial de la empresa a la que se desea invitar</p>
                <v-autocomplete 
                    v-model="proveedor" 
                    clearable 
                    :items="listaProveedores" 
                    outlined 
                    item-text="nombre_comercial" 
                    return-object
                    :search-input.sync="nombreComercialModel" 
                    label="Nombre comercial" 
                    @keyup="cargarListaProveedoresDebounced"
                    :loading="cargandoListaProveedores"
                    hide-details
                    class="mb-4"
                />
                <InformacionProvedor v-if="proveedor" :providerData="proveedor" />

                <div class="d-flex justify-center align-center py-4" style="background-color: #FFFFFF; gap: 16px; position: sticky; bottom: 0">
                    <v-btn 
                        @click.stop="cerrarModal" 
                        class="flex-grow-1 flex-shrink-1" 
                        color="secondary"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn 
                        @click.stop="invitarProveedor"
                        class="flex-grow-1 flex-shrink-1" 
                        color="primary"
                        :disabled="!proveedor"
                        :loading="cargandoListaProveedores"
                    >
                        Invitar
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { isNil, debounce } from 'lodash';
import InformacionProvedor from "@/components/InfomacionProveedorComponent.vue";

export default {
    name: 'InvitacionConvocatoria',
    emits: ['on-visibility-change', 'on-invite'],
    components: { InformacionProvedor },
    props: {
        isOpen: { type: Boolean },
    },
    data: () =>({
        internalOpen: false,
        proveedor: null,
        cargandoListaProveedores: false,
        listaProveedores: [],
        nombreComercial: '',
    }),
    computed: {
        nombreComercialModel: {
            get() {
                return this.nombreComercial;
            },
            set(value) {
                this.nombreComercial = value;
            },
        },
    },
    methods: {
        async cargarListaProveedores() {
            try {
                this.listaProveedores = [];
                this.cargandoListaProveedores = true;
                const params = { busqueda: this.nombreComercial };
    
                const response = await this.services.Paac.getListarProveedores(params);
                if (response.status === 200) {
                    this.listaProveedores = response.data;
                }
            } catch (error) {
                this.pushAppMessage({ message: 'Error al cargar la lista de proveedores', type: 'error' });
            } finally {
                this.cargandoListaProveedores = false;
            }
        },
        limpiarBusqueda() {
            this.proveedor = null;
            this.listaProveedores = [];
            this.cargandoListaProveedores = false;
            this.nombreComercial = '';
        },
        invitarProveedor() {
            this.$emit('on-invite', { ...this.proveedor });
            this.limpiarBusqueda();
        },
        cerrarModal() {
            this.internalOpen = false;
            this.limpiarBusqueda();
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },
    created() {
        this.cargarListaProveedoresDebounced = debounce(this.cargarListaProveedores, 400);
    },
}
</script>