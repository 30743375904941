<template>
<v-dialog v-model="internalOpen" max-width="700" persistent>
    <div style="background-color: #FFFFFF; padding: 0 1em 2em; position: relative;">
        <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
            <v-btn 
                style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                class="px-4 py-4"
                @click.stop="cerrarModal" 
                text
                rounded
            >
                <v-icon>mdi-window-close</v-icon>
            </v-btn>
        </div>
        <div class="px-1 mt-1">
            <v-form ref="form">
                <v-textarea
                    v-model="form.encabezado"
                    label="Encabezado"
                    placeholder="Encabezado"
                    outlined
                    clearable
                    no-resize
                    rows="3"
                    :error-messages="encabezadoErrors"
                    @change="$v.form.encabezado.$touch()"
                    @blur="$v.form.encabezado.$touch()"
                />
                <div class="d-flex flex-wrap flex-md-nowrap" style="gap: 16px;">
                    <div class="flex-grow-1 flex-shrink-1" style="width: 100%">
                        <!-- Fecha de recepcion-->
                        <v-menu transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-text-field
                                        @click:clear="form.fecha_recepcion = null"
                                        :value="fechaHoraFormatted(form.fecha_recepcion)"
                                        :error-messages="fecha_recepcionErrors"
                                        append-icon="mdi-calendar"
                                        clearable
                                        label="Fecha de recepción "
                                        outlined
                                        placeholder="Seleccione una fecha"
                                        readonly
                                        @change="$v.form.fecha_recepcion.$touch()"
                                        @blur="$v.form.fecha_recepcion.$touch()"
                                    />
                                </span>
                            </template>
                            <div @click.stop>
                                <vc-date-picker
                                    v-model="form.fecha_recepcion"
                                    mode="datetime"
                                    locale="es"
                                    :min-date="fechaMinimaDeRecepcion"
                                />
                            </div>
                        </v-menu>
                    </div>
                    <div class="flex-grow-1 flex-shrink-1" style="width: 100%">
                        <!-- Fecha de evaluacion-->
                        <v-menu transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-text-field
                                        @click:clear="form.fecha_evaluacion = null"
                                        :value="fechaFormatted(form.fecha_evaluacion)"
                                        :error-messages="fecha_evaluacionErrors"
                                        append-icon="mdi-calendar"
                                        clearable
                                        label="Fecha de evaluación "
                                        outlined
                                        placeholder="Seleccione una fecha"
                                        readonly
                                        @change="$v.form.fecha_evaluacion.$touch()"
                                        @blur="$v.form.fecha_evaluacion.$touch()"
                                    />
                                </span>
                            </template>
                            <div @click.stop>
                                <vc-date-picker
                                    v-model="form.fecha_evaluacion"
                                    mode="date"
                                    locale="es"
                                    :min-date="fechaMinimaDeEvaluacion"
                                />
                            </div>
                        </v-menu>
                    </div>
                </div>
                <div class="d-flex flex-wrap flex-md-nowrap" style="gap: 16px;">
                    <div class="flex-grow-1 flex-shrink-1" style="width: 100%">
                        <!-- Fecha de resultados-->
                        <v-menu transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-text-field
                                        @click:clear="form.fecha_resultados = null"
                                        :error-messages="fecha_resultadosErrors"
                                        :value="fechaFormatted(form.fecha_resultados)"
                                        append-icon="mdi-calendar"
                                        clearable
                                        label="Fecha de resultados"
                                        outlined
                                        placeholder="Seleccione una fecha"
                                        readonly
                                        @change="$v.form.fecha_resultados.$touch()"
                                        @blur="$v.form.fecha_resultados.$touch()"
                                    />
                                </span>
                            </template>
                            <div @click.stop>
                                <vc-date-picker
                                    v-model="form.fecha_resultados"
                                    mode="date"
                                    locale="es"
                                    :min-date="fechaMinimaDeResultados"
                                />
                            </div>
                        </v-menu>
                    </div>
                    <div class="flex-grow-1 flex-shrink-1" style="width: 100%">
                        <v-file-input
                            v-model="form.adjunto"
                            label="Adjunto"
                            placeholder="Seleccione un PDF"
                            outlined
                            light
                            accept="application/pdf"
                            :error-messages="adjuntoErrors"
                            @change="$v.form.adjunto.$touch()"
                            @blur="$v.form.adjunto.$touch()"
                        />
                    </div>
                </div>

                <v-checkbox
                    v-model="form.proceso_publico"
                    label="Convocatoria pública"
                    class="mt-0"
                />

                <div class="d-flex justify-center align-center" style="gap: 16px;">
                    <v-btn 
                        @click.stop="cerrarModal" 
                        class="flex-grow-1 flex-shrink-1" 
                        color="secondary"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn 
                        @click.stop="guardarConvocatoria"
                        class="flex-grow-1 flex-shrink-1" 
                        color="primary"
                    >
                        Guardar
                    </v-btn>
                </div>
            </v-form>
        </div>
    </div>
</v-dialog>
</template>
<script>
import { isNil } from 'lodash';
import { required, maxLength } from 'vuelidate/lib/validators';
import moment from 'moment';
import { Validator } from '@/utils/form-validation.js';

export default {
    name: 'CrearConvocatoria',
    emits: ['on-visibility-change', 'on-save'],
    props: {
        isOpen: { type: Boolean },
    },
    validations () {
        return {
            form: {
                encabezado: { required, maxLength: maxLength(255) },
                fecha_evaluacion: { required },
                fecha_recepcion: { required },
                fecha_resultados: { required },
                adjunto: { required },
            }, 
        };
    },
    data: () =>({
        internalOpen: false,
        form: {
            encabezado: null,
            fecha_recepcion: null,
            fecha_evaluacion: null,
            fecha_resultados: null,
            adjunto: null,
            proceso_publico: false,
        },
    }),
    computed: {
        encabezadoErrors() {
            return new Validator(this.$v.form.encabezado).detect().getResult();
        },
        fecha_evaluacionErrors() {
            return new Validator(this.$v.form.fecha_evaluacion).detect().getResult();
        },
        fecha_recepcionErrors() {
            return new Validator(this.$v.form.fecha_recepcion).detect().getResult();
        }, 
        fecha_resultadosErrors() {
            return new Validator(this.$v.form.fecha_resultados).detect().getResult();
        },
        adjuntoErrors() {
            return new Validator(this.$v.form.adjunto).detect().getResult();
        },
        // Campos del formulario
        fechaRecepcion() {
            return this.form.fecha_recepcion
        },
        fechaEvaluacion() {
            return this.form.fecha_evaluacion
        },
        fechaResultados() {
            return this.form.fecha_resultados
        },
        fechaMinimaDeEvaluacion() {
            if (!this.form.fecha_recepcion) return null;
            
            const fechaMoment = moment(this.fechaRecepcion).add(1, 'day').startOf('day');
            return fechaMoment.toDate();
        },
        fechaMinimaDeResultados() {
            if (!this.form.fecha_evaluacion) return null;
            
            const fechaMoment = moment(this.fechaEvaluacion).add(1, 'day').startOf('day');
            return fechaMoment.toDate();
        },
        fechaMinimaDeRecepcion() {
            return moment().startOf('day').toDate();
        },
    },
    methods: {
        fechaHoraFormatted(fecha) {
            return fecha ? moment(fecha).format("DD-MM-YYYY HH:mm:ss") : '';
        },
        fechaFormatted(fecha) {
            return fecha ? moment(fecha).format("DD-MM-YYYY") : '';
        },
        limpiarFormulario() {
            this.$v.form.$reset();
            this.$refs.form.reset();
            this.$v.form.adjunto.$reset();
        },
        guardarConvocatoria() {
            this.$v.$touch();

            if (this.$v.$invalid) return;
            this.$emit('on-save', { ...this.form, proceso_publico: Boolean(this.form.proceso_publico) });
            this.limpiarFormulario();
        },
        cerrarModal() {
            this.internalOpen = false;
            this.limpiarFormulario();
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
        fechaRecepcion() {
            this.form.fecha_evaluacion = null;
            this.form.fecha_resultados = null;
        },
        fechaEvaluacion() {
            this.form.fecha_resultados = null;
        },
    },
}
</script>