<template>
    <v-dialog v-model="internalOpen" max-width="700">
        <div style="background-color: #FFFFFF; padding: 0 1em 2em; position: relative;">
            <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="px-4 py-4"
                    @click.stop="cerrarModal" 
                    text
                    rounded
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <div class="px-1 mt-1">
                <v-form ref="form">
                    <v-textarea
                        v-model="form.encabezado"
                        label="Encabezado"
                        placeholder="Encabezado"
                        outlined
                        clearable
                        no-resize
                        rows="3"
                        :error-messages="encabezadoErrors"
                        @change="$v.form.encabezado.$touch()"
                        @blur="$v.form.encabezado.$touch()"
                    />

                    <!-- Fecha de recepcion-->
                    <v-menu transition="scale-transition" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-text-field
                                    v-model="fechas.fecha_recepcion"
                                    @click:clear="resetearFechaRecepcion"
                                    :error-messages="fecha_recepcionErrors"
                                    append-icon="mdi-calendar"
                                    clearable
                                    label="Fecha de recepción "
                                    outlined
                                    placeholder="Seleccione una fecha"
                                    readonly
                                    @change="$v.form.fecha_recepcion.$touch()"
                                    @blur="$v.form.fecha_recepcion.$touch()"
                                />
                            </span>
                        </template>
                        <div @click.stop>
                            <vc-date-picker
                                v-model="form.fecha_recepcion"
                                mode="datetime"
                                locale="es"
                                @input="manejarCambioFechaRecepcion"
                                :min-date="fechaMinimaDeRecepcion"
                            />
                        </div>
                    </v-menu>

                    <!-- Fecha de evaluacion-->
                    <v-menu transition="scale-transition" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-text-field
                                    v-model="fechas.fecha_evaluacion"
                                    @click:clear="resetearFechaEvaluacion"
                                    :error-messages="fecha_evaluacionErrors"
                                    append-icon="mdi-calendar"
                                    clearable
                                    label="Fecha de evaluación "
                                    outlined
                                    placeholder="Seleccione una fecha"
                                    readonly
                                    @change="$v.form.fecha_evaluacion.$touch()"
                                    @blur="$v.form.fecha_evaluacion.$touch()"
                                />
                            </span>
                        </template>
                        <div @click.stop>
                            <vc-date-picker
                                v-model="form.fecha_evaluacion"
                                mode="date"
                                locale="es"
                                :min-date="fechaMinimaDeEvaluacion"
                                @input="manejarCambioFechaEvaluacion"
                            />
                        </div>
                    </v-menu>

                    <!-- Fecha de resultados-->
                    <v-menu transition="scale-transition" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="fechas.fecha_resultados"
                                @click:clear="resetearFechaResultados"
                                :error-messages="fecha_resultadosErrors"
                                append-icon="mdi-calendar"
                                clearable
                                label="Fecha de resultados"
                                outlined
                                placeholder="Seleccione una fecha"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @change="$v.form.fecha_resultados.$touch()"
                                @blur="$v.form.fecha_resultados.$touch()"
                            />
                        </template>
                        <div @click.stop>
                            <vc-date-picker
                                v-model="form.fecha_resultados"
                                mode="date"
                                locale="es"
                                :min-date="fechaMinimaDeResultados"
                            />
                        </div>
                    </v-menu>
    
                    <v-checkbox
                        v-model="form.proceso_publico"
                        label="Convocatoria pública"
                        class="mt-0"
                    />
    
                    <div class="d-flex justify-center align-center" style="gap: 16px;">
                        <v-btn 
                            @click.stop="cerrarModal" 
                            class="flex-grow-1 flex-shrink-1" 
                            color="secondary"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn 
                            @click.stop="guardarConvocatoria"
                            class="flex-grow-1 flex-shrink-1" 
                            color="primary"
                        >
                            Guardar
                        </v-btn>
                    </div>
                </v-form>
            </div>
        </div>
    </v-dialog>
    </template>
    <script>
    import { isNil } from 'lodash';
    import { required, maxLength } from 'vuelidate/lib/validators';
    import moment from 'moment';
    import { Validator } from '@/utils/form-validation.js';
    
    export default {
        name: 'EditarConvocatoria',
        emits: ['on-visibility-change', 'on-save'],
        props: {
            isOpen: { type: Boolean },
            convocatoria: { type: Object },
        },
        validations () {
            return {
                form: {
                    encabezado: { required, maxLength: maxLength(255) },
                    fecha_evaluacion: { required },
                    fecha_recepcion: { required },
                    fecha_resultados: { required },
                }, 
            };
        },
        data: () =>({
            internalOpen: false,
            form: {
                encabezado: null,
                fecha_recepcion: null,
                fecha_evaluacion: null,
                fecha_resultados: null,
                proceso_publico: false,
            },
            fechas: {
                fecha_recepcion: null,
                fecha_evaluacion: null,
                fecha_resultados: null,
            },
        }),
        computed: {
            encabezadoErrors() {
                return new Validator(this.$v.form.encabezado).detect().getResult();
            },
            fecha_evaluacionErrors() {
                return new Validator(this.$v.form.fecha_evaluacion).detect().getResult();
            },
            fecha_recepcionErrors() {
                return new Validator(this.$v.form.fecha_recepcion).detect().getResult();
            }, 
            fecha_resultadosErrors() {
                return new Validator(this.$v.form.fecha_resultados).detect().getResult();
            },
            // Campos del formulario
            fechaRecepcion() {
                return this.form.fecha_recepcion
            },
            fechaEvaluacion() {
                return this.form.fecha_evaluacion
            },
            fechaResultados() {
                return this.form.fecha_resultados
            },
            fechaMinimaDeEvaluacion() {
                if (!this.form.fecha_recepcion) return null;
                
                const fechaMoment = moment(this.fechaRecepcion).add(1, 'day').startOf('day');
                return fechaMoment.toDate();
            },
            fechaMinimaDeResultados() {
                if (!this.form.fecha_evaluacion) return null;
                
                const fechaMoment = moment(this.fechaEvaluacion).add(1, 'day').startOf('day');
                return fechaMoment.toDate();
            },
            fechaMinimaDeRecepcion() {
                return moment().startOf('day').toDate();
            },
        },
        methods: {
            fechaHoraFormatted(fecha) {
                return fecha ? moment(fecha).format("DD-MM-YYYY HH:mm:ss") : '';
            },
            fechaFormatted(fecha) {
                return fecha ? moment(fecha).format("DD-MM-YYYY") : '';
            },
            limpiarFormulario() {
                this.$v.form.$reset();
                this.$refs.form.reset();
            },
            guardarConvocatoria() {
                this.$v.$touch();
    
                if (this.$v.$invalid) return;
                this.$emit('on-save', { ...this.form, proceso_publico: Boolean(this.form.proceso_publico) });
                this.limpiarFormulario();
            },
            cerrarModal() {
                this.internalOpen = false;
                this.limpiarFormulario();
            },
            llenarCampos() {
                const convocatoria = this.convocatoria;
                if (!convocatoria) return this.limpiarFormulario();

                this.form.encabezado = convocatoria.encabezado;
                this.form.fecha_recepcion = moment(convocatoria.fecha_recepcion).toDate();
                this.form.fecha_evaluacion = moment(convocatoria.fecha_evaluacion).toDate();
                this.form.fecha_resultados = moment(convocatoria.fecha_resultados).toDate();
                this.form.proceso_publico = convocatoria.proceso_publico;
            },
            manejarCambioFechaRecepcion() {
                this.form.fecha_evaluacion = null;
                this.form.fecha_resultados = null;
            },
            manejarCambioFechaEvaluacion() {
                this.form.fecha_resultados = null;
            },
            resetearFechaRecepcion() {
                this.form.fecha_recepcion = null;
                this.form.fecha_evaluacion = null;
                this.form.fecha_resultados = null;
            },
            resetearFechaEvaluacion() {
                this.form.fecha_evaluacion = null;
                this.form.fecha_resultados = null;
            },
            resetearFechaResultados() {
                this.form.fecha_resultados = null;
            },
        },
        watch: {
            isOpen(value) {
                if (isNil(value)) return;
    
                this.internalOpen = value;
            },
            internalOpen(value) {
                this.$emit('on-visibility-change', value);
            },
            convocatoria: {
                handler(value) {
                    if (!value) return this.limpiarFormulario();
                    this.llenarCampos();
                },
                deep: true,
            },
            fechaRecepcion(value) {
                this.fechas.fecha_recepcion = this.fechaHoraFormatted(value);
            },
            fechaEvaluacion(value) {
                this.fechas.fecha_evaluacion = this.fechaFormatted(value);
            },
            fechaResultados(value) {
                this.fechas.fecha_resultados = this.fechaFormatted(value);
            },
        },
    }
    </script>