<template>
    <v-container>
        <template v-if="tieneAcceso">
            <ListaConvocatoriasAdmin v-if="tieneRolAdministrador" />
            <ListaConvocatoriasProveedor v-else  />
        </template>
        <EmptyComponent v-else empty text="No tiene acceso a esta lista"  />
    </v-container>
</template>
<script>
import ListaConvocatoriasAdmin from './components/ListaConvocatoriasAdmin.vue';
import ListaConvocatoriasProveedor from './components/ListaConvocatoriasProveedor.vue';
import { EmptyComponent } from '@/components/utils';

export default {
    name: 'CmConvocatoriasView',
    components: { ListaConvocatoriasAdmin, ListaConvocatoriasProveedor, EmptyComponent },
    computed: {
        tieneRolProveedor() {
            return this.haveRole('ROLE_CM_PROVEEDOR_CONVOCATORIAS_VIEW');
        },
        tieneRolAdministrador() {
            return this.haveRole('ROLE_CM_ADMINISTRACION_CONVOCATORIAS_VIEW');     
        },
        tieneAcceso() {
            return this.tieneRolProveedor || this.tieneRolAdministrador;
        },
    },
}
</script>